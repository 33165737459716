import UserModelCommon from 'common/models/user';

export default class UserModel extends UserModelCommon {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    user: UserModel;
  }
}
