function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
/* eslint-disable no-unused-vars */
import { ImmerChangeset } from 'ember-form-changeset-validations';
import { EventEmitter } from 'events';
/**
 * Changeset emitting events on set. And validating one field at a time.
 */
export default class BaseChangeset extends ImmerChangeset {
  async validateOne(key, validation) {
    // eslint-disable-next-line ember/classic-decorator-no-classic-methods
    const error = await validation(this.get(key), key, this['draftData']);
    if (error) {
      return this.addError(key, error);
    }
    this.removeError(key);
  }
  constructor(data) {
    super(data);
    _defineProperty(this, "emitter", new EventEmitter());
  }
  onSet(fn) {
    return this.emitter.on('set', fn);
  }
  set(key, value) {
    super.set(key, value);
    this.emitter.emit('set', key);
  }
}