import type { BaseActionProductStatus } from 'common/enums/base-action-product-status.enum';
import type { Duration } from 'common/interfaces/duration';
import type { OgMetadata } from 'common/interfaces/og-metadata';
import type { FileObject } from 'back-office/services/base-document';
import type { TagTypes } from 'common/enums/tag-type.enum';

export type TagActionProductDTO = {
  id: string;
  name: string;
  type: TagTypes;
};

export type SimilarDTO = {
  id: string;
  name: string;
};

export type BaseActionProductDTO = {
  id?: string;
  name: string;
  slug: string;
  code: string;
  status: BaseActionProductStatus;
  ogImage?: FileObject;
  ogMetadata: OgMetadata;
  duration?: Duration;
  cover?: FileObject;
  formatorImage?: FileObject;
  tags: TagActionProductDTO[];
  testimonyImages: FileObject[];
  video: string;
  testimony: string;
};
