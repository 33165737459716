import { service } from '@ember/service';
import type ExtendedStoreService from '@triptyk/ember-utils/services/extended-store';
import { ProductChangeset } from 'back-office/changesets/product';
import type ProductModel from 'back-office/models/product';
import type { ChangesetService } from 'common/services/changesets/changeset-service';
import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type ErrorHandlerService from 'common/services/error-handler';
import BaseActionProductService from './base-action-product';
import { saveFile, saveFileFromDiff } from 'back-office/utils/save-file';
import type TestimonyImageService from '../testimony-image';
import type { Relation } from 'back-office/utils/file-to-form';

export default class ProductService
  extends BaseActionProductService
  implements ChangesetService<ProductChangeset>
{
  @service declare store: StoreService;
  @service declare extendedStore: ExtendedStoreService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare errorHandler: ErrorHandlerService;
  @service declare testimonyImage: TestimonyImageService;

  async save(changeset: ProductChangeset): Promise<ProductModel> {
    changeset.execute();
    const changesetData = changeset.data;
    const product = this.extendedStore.peekOrCreateRecord(
      'product',
      changesetData.id ?? ''
    );
    const relation: Relation = {
      ressourceName: 'product',
      fieldName: 'product',
      id: product.id,
    };

    if (product == null) {
      throw new Error('model not found');
    }

    product.setProperties({
      status: changesetData.status,
      ogMetadata: {
        title: changesetData.ogMetadata?.title,
        description: changesetData.ogMetadata?.description,
        keywords: changesetData.ogMetadata?.keywords,
      },
      similarProducts: changesetData.similarProducts.map((product) =>
        this.store.peekRecord('product', product.id)
      ),
      tags: changesetData.tags.map((tag) =>
        this.store.peekRecord('tag', tag.id)
      ),
      testimony: changesetData.testimony,
      video: changesetData.video,
    });

    try {
      await changeset.save();
      await product.save();
      await Promise.all([
        await saveFile(
          product,
          changesetData.cover,
          await product.cover,
          this.cover,
          relation
        ),
        await saveFile(
          product,
          changesetData?.ogImage,
          await product.ogImage,
          this.ogImage,
          relation
        ),
        await saveFile(
          product,
          changesetData?.formatorImage,
          await product.formatorImage,
          this.formatorImage,
          relation
        ),
        await saveFileFromDiff(
          product,
          changeset,
          this.testimonyImage,
          relation
        ),
      ]);

      this.flashMessages.success(
        this.intl.t('components.pages.products.success_edit')
      );
    } catch (e: unknown) {
      const error = (await e) as AdapterError;
      this.errorHandler.handle(changeset, error.errors);
    }
    return product;
  }

  async changesetFrom(id: string) {
    let product = this.extendedStore.peekOrFail('product', id) as ProductModel;
    const changeset = new ProductChangeset({
      ...(await this.getCommonProperties(product)),
      type: product.type,
      similarProducts: this.getSimilarPojo(
        await product.get('similarProducts')
      ),
    });
    return changeset;
  }
}

declare module '@ember/service' {
  interface Registry {
    'changesets/product': ProductService;
  }
}
