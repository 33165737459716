import DescriptionModelCommon from 'common/models/description';

export interface Description {
  short?: string;
  full?: string;
}

export default class DescriptionModel extends DescriptionModelCommon {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    description: DescriptionModel;
  }
}
