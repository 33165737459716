import { registerDestructor } from '@ember/destroyable';
import { validateOne } from 'common/utils/validate-and-map';
/**
 * Based on events for smooth field by field validation.
 * This changeset is tied to the owner's lifecycle.
 */
export default function makeChangesetReactive(owner, changeset, schema) {
  const listener = changeset.onSet(async key => {
    await changeset.validateOne(key, validateOne(schema));
  });
  registerDestructor(owner, () => {
    listener.removeAllListeners();
  });
}