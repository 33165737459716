import config from 'back-office/config/environment';
import EmberRouter from '@embroider/router';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('404', { path: '/*path' });
  this.route('login');
  this.route('forgot-password');
  this.route('reset-password');
  this.route('set-password');
  this.route('logout');
  this.route('dashboard', function () {
    this.route('users', function () {
      this.route('edit', {
        path: 'edit/:id',
      });
      this.route('create');
    });
    this.route('settings');
    this.route('profile');
    this.route('products', function () {
      this.route('edit', {
        path: 'edit/:id',
      });
    });
    this.route('actions', function () {
      this.route('edit', {
        path: 'edit/:id',
      });
    });
    this.route('learnings', function () {
      this.route('edit', {
        path: 'edit/:id',
      });
    });
    this.route('alerts');
    this.route('exports');
    this.route('tags', function () {
      this.route('edit', {
        path: 'edit/:id',
      });
      this.route('create');
    });
  });
});
