import { ValidationError } from 'yup';
export async function validateAndMapErrors(schema, dto) {
  try {
    await schema.validate(dto, {
      abortEarly: false
    });
  } catch (e) {
    if (e instanceof ValidationError) {
      return e.inner.map(e => {
        return {
          message: e.message,
          params: e.params,
          key: e.path ?? '',
          value: e.value,
          originalValue: ''
        };
      });
    }
  }
  return [];
}
export function validateOne(schema) {
  return async (value, field, draft) => {
    try {
      // validateAt seems to behave weirdly with .when conditions. Using validate instead.
      await schema.validate(draft, {
        abortEarly: false
      });
    } catch (e) {
      if (e instanceof ValidationError) {
        const error = e.inner.filter(e => e.path === field);
        return {
          message: error[0]?.message,
          params: error[0]?.params,
          key: error[0]?.path ?? '',
          value: error[0]?.value,
          originalValue: ''
        };
      }
    }
    return undefined;
  };
}