import type TestimonyImageModel from 'back-office/models/testimony-image';
import BaseDocumentService from './base-document';

export default class TestimonyImageService extends BaseDocumentService<TestimonyImageModel> {
  entityName = 'testimony-images';
}

declare module '@ember/service' {
  interface Registry {
    testimonyImage: TestimonyImageService;
  }
}
