var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _dec17, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10, _descriptor11, _descriptor12, _descriptor13, _descriptor14, _descriptor15, _descriptor16, _descriptor17;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
let BaseActionProductModel = (_dec = attr(), _dec2 = attr(), _dec3 = attr(), _dec4 = attr(), _dec5 = attr(), _dec6 = attr(), _dec7 = attr(), _dec8 = attr(), _dec9 = attr('string'), _dec10 = attr(), _dec11 = hasMany('module', {
  async: true
}), _dec12 = hasMany('family', {
  async: true
}), _dec13 = hasMany('tag', {
  async: false
}), _dec14 = belongsTo('cover', {
  async: true
}), _dec15 = belongsTo('og-image', {
  async: true
}), _dec16 = hasMany('testimony-image'), _dec17 = belongsTo('formator-image'), (_class = class BaseActionProductModel extends Model {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "name", _descriptor, this);
    _initializerDefineProperty(this, "slug", _descriptor2, this);
    _initializerDefineProperty(this, "code", _descriptor3, this);
    _initializerDefineProperty(this, "video", _descriptor4, this);
    _initializerDefineProperty(this, "testimony", _descriptor5, this);
    _initializerDefineProperty(this, "introduction", _descriptor6, this);
    _initializerDefineProperty(this, "duration", _descriptor7, this);
    _initializerDefineProperty(this, "targetAudience", _descriptor8, this);
    _initializerDefineProperty(this, "status", _descriptor9, this);
    _initializerDefineProperty(this, "ogMetadata", _descriptor10, this);
    _initializerDefineProperty(this, "modules", _descriptor11, this);
    _initializerDefineProperty(this, "families", _descriptor12, this);
    _initializerDefineProperty(this, "tags", _descriptor13, this);
    _initializerDefineProperty(this, "cover", _descriptor14, this);
    _initializerDefineProperty(this, "ogImage", _descriptor15, this);
    _initializerDefineProperty(this, "testimonyImages", _descriptor16, this);
    _initializerDefineProperty(this, "formatorImage", _descriptor17, this);
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "name", [_dec], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "slug", [_dec2], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "code", [_dec3], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "video", [_dec4], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "testimony", [_dec5], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "introduction", [_dec6], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "duration", [_dec7], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "targetAudience", [_dec8], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "status", [_dec9], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "ogMetadata", [_dec10], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor11 = _applyDecoratedDescriptor(_class.prototype, "modules", [_dec11], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor12 = _applyDecoratedDescriptor(_class.prototype, "families", [_dec12], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor13 = _applyDecoratedDescriptor(_class.prototype, "tags", [_dec13], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor14 = _applyDecoratedDescriptor(_class.prototype, "cover", [_dec14], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor15 = _applyDecoratedDescriptor(_class.prototype, "ogImage", [_dec15], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor16 = _applyDecoratedDescriptor(_class.prototype, "testimonyImages", [_dec16], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor17 = _applyDecoratedDescriptor(_class.prototype, "formatorImage", [_dec17], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class));
export { BaseActionProductModel as default };