import ProductModelCommon from 'common/models/product';

export default class ProductModel extends ProductModelCommon {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    product: ProductModel;
  }
}
