import type CoverModel from 'back-office/models/cover';
import BaseDocumentService from './base-document';

export default class CoverService extends BaseDocumentService<CoverModel> {
  entityName = 'covers';
}

declare module '@ember/service' {
  interface Registry {
    cover: CoverService;
  }
}
