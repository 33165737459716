/* eslint-disable unicorn/filename-case */
import Service, { service } from '@ember/service';
import { UserChangeset } from 'back-office/changesets/user';
import type UserModel from 'back-office/models/user';
import type StoreService from '@ember-data/store';
import type { ChangesetService } from 'common/services/changesets/changeset-service';
import type ExtendedStoreService from '@triptyk/ember-utils/services/extended-store';

export default class UserService
  extends Service
  implements ChangesetService<UserChangeset>
{
  @service declare store: StoreService;
  @service declare extendedStore: ExtendedStoreService;

  emptyChangeset() {
    return new UserChangeset({
      lastName: '',
      firstName: '',
      email: '',
      role: 'admin',
      newsAgreement: false,
    });
  }

  changesetFrom(id: string) {
    const user = this.extendedStore.peekOrFail('user', id);
    const changeset = new UserChangeset({
      id: user.id,
      lastName: user.lastName,
      firstName: user.firstName,
      email: user.email,
      role: user.role,
      newsAgreement: user.newsAgreement,
    });

    return changeset;
  }

  async save(changeset: UserChangeset): Promise<UserModel> {
    changeset.execute();

    const user = this.extendedStore.peekOrCreateRecord(
      'user',
      changeset.get('id')
    ) as UserModel;

    user.setProperties({
      lastName: changeset.data.lastName,
      firstName: changeset.data.firstName,
      email: changeset.data.email,
      role: changeset.data.role,
      termsAgreement: true,
      newsAgreement: changeset.data.newsAgreement,
    });

    await user.save();
    await changeset.save();

    return user;
  }
}

declare module '@ember/service' {
  interface Registry {
    'changesets/user': UserService;
  }
}
