import BaseChangeset from 'common/changesets/base';
import type { TagTypes } from 'common/enums/tag-type.enum';

export type TagDTO = {
  id?: string;
  name: string;
  type: TagTypes;
};

export class TagChangeset extends BaseChangeset<TagDTO> {}
