/* eslint-disable @typescript-eslint/no-explicit-any */

import type { PersistedFile } from 'back-office/services/base-document';
import type { ImmerChangeset } from 'ember-form-changeset-validations';

export function addFileToChangeset<C extends ImmerChangeset<any>>(
  changeset: C,
  field: string,
  files: File[]
) {
  if (Array.isArray(changeset.get(field))) {
    changeset.set(field, [
      ...(changeset.get(field) as []),
      ...files.map((file: File) => ({
        filename: file.name,
        file: file,
        size: file.size,
      })),
    ]);
  } else {
    changeset.set(field, {
      id: (changeset.get(field) as PersistedFile)?.id,
      filename: files[0]?.name,
      file: files[0],
      size: files[0]?.size,
    });
  }
}

export function removeFileToChangeset<C extends ImmerChangeset<any>>(
  changeset: C,
  field: string,
  path: string
) {
  if (Array.isArray(changeset.get(field))) {
    let files: PersistedFile[] = changeset.get(
      field
    ) as unknown as PersistedFile[];
    let toDelete = changeset.get(path);

    changeset.set(
      field,
      files.filter((file) => file !== toDelete)
    );
  } else {
    changeset.set(field, undefined);
  }
}
