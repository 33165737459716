/* eslint-disable ember/classic-decorator-no-classic-methods */
/* eslint-disable ember/no-get */
import type { BaseActionProductDTO } from './base-action-product';
import type { Address } from 'common/interfaces/address';
import type { TargetAudience } from 'common/enums/target-audience.enum';
import BaseChangeset from 'common/changesets/base';
import type { ActionType } from 'common/enums/action-type.enum';

export type ActionDTO = BaseActionProductDTO & {
  targetAudience: TargetAudience;
  startAt: string;
  endAt: string;
  address: Address;
  type: ActionType;
};

export class ActionChangeset extends BaseChangeset<ActionDTO> {
  get fullAddress() {
    return this.get('address')
      ? `${this.get('address').streetAndNumber} ${
          this.get('address').complement
        } - ${this.get('address').zip} ${this.get('address').town} ${
          this.get('address').country
        }`
      : '';
  }
}
