import { service } from '@ember/service';
import type ExtendedStoreService from '@triptyk/ember-utils/services/extended-store';
import type { ChangesetService } from 'common/services/changesets/changeset-service';

import type StoreService from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type FlashMessageService from 'ember-cli-flash/services/flash-messages';
import type ErrorHandlerService from 'common/services/error-handler';
import BaseActionProductService from './base-action-product';
import { ActionChangeset } from 'back-office/changesets/action';
import type ActionModel from 'back-office/models/action';
import type TestimonyImageService from '../testimony-image';
import type { Relation } from 'back-office/utils/file-to-form';
import { saveFile, saveFileFromDiff } from 'back-office/utils/save-file';
/* jscpd:ignore-start */

export default class ActionService
  extends BaseActionProductService
  implements ChangesetService<ActionChangeset>
{
  @service declare store: StoreService;
  @service declare extendedStore: ExtendedStoreService;
  @service declare intl: IntlService;
  @service declare flashMessages: FlashMessageService;
  @service declare errorHandler: ErrorHandlerService;
  @service declare testimonyImage: TestimonyImageService;

  async save(changeset: ActionChangeset): Promise<ActionModel> {
    changeset.execute();
    const changesetData = changeset.data;
    const action = this.extendedStore.peekOrCreateRecord(
      'action',
      changesetData.id
    );
    const relation: Relation = {
      ressourceName: 'action',
      fieldName: 'action',
      id: action.id,
    };

    action.setProperties({
      status: changesetData.status,
      ogMetadata: {
        title: changesetData.ogMetadata?.title,
        description: changesetData.ogMetadata?.description,
        keywords: changesetData.ogMetadata?.keywords,
      },
      tags: changesetData.tags.map((tag) =>
        this.store.peekRecord('tag', tag.id)
      ),
      testimony: changesetData.testimony,
      video: changesetData.video,
    });

    try {
      await changeset.save();
      await action.save();
      await Promise.all([
        await saveFile(
          action,
          changesetData.cover,
          await action.cover,
          this.cover,
          relation
        ),
        await saveFile(
          action,
          changesetData?.ogImage,
          await action.ogImage,
          this.ogImage,
          relation
        ),
        await saveFile(
          action,
          changesetData?.formatorImage,
          await action.formatorImage,
          this.formatorImage,
          relation
        ),
        await saveFileFromDiff(
          action,
          changeset,
          this.testimonyImage,
          relation
        ),
      ]);
      this.flashMessages.success(
        this.intl.t('components.pages.actions.success_edit')
      );
    } catch (e: unknown) {
      const error = (await e) as AdapterError;
      this.errorHandler.handle(changeset, error.errors);
    }
    return action;
  }

  async changesetFrom(id: string) {
    let action = this.extendedStore.peekOrFail('action', id) as ActionModel;
    const changeset = new ActionChangeset({
      ...(await this.getCommonProperties(action)),
      type: action.type,
      startAt: action.startAt,
      endAt: action.endAt,
      address: action.address,
      targetAudience: action.targetAudience,
    });
    return changeset;
  }
}

declare module '@ember/service' {
  interface Registry {
    'changesets/action': ActionService;
  }
}
/* jscpd:ignore-end */
