import type { FileObject } from 'back-office/services/base-document';
import type DocumentModelCommon from 'common/addon/models/document';
import type { DocumentDiff } from './document-diff';
import type { Relation } from './file-to-form';
import { fileToFormData } from './file-to-form';
import type ProductModel from 'back-office/models/product';
import type ActionModel from 'back-office/models/action';
import type { ProductChangeset } from 'back-office/changesets/product';
import type { ActionChangeset } from 'back-office/changesets/action';
import documentsDiff from './document-diff';
import type CoverService from 'back-office/services/cover';
import type FormatorImageService from 'back-office/services/formator-image';
import type OgImageService from 'back-office/services/og-image';

interface Saveable {
  save: (formData: FormData) => unknown;
}

export async function saveFile(
  action: ActionModel | ProductModel,
  file: FileObject | null | undefined,
  model: DocumentModelCommon | undefined,
  service: CoverService | OgImageService | FormatorImageService,
  relation: Relation
) {
  if (file && fileToFormData(file, relation)) {
    return service.save(fileToFormData(file, relation) as FormData);
  }
  if (action.formatorImage !== undefined && file === undefined) {
    await model?.destroyRecord();
  }
}

export async function saveFileFromDiff(
  model: ActionModel | ProductModel,
  changeset: ActionChangeset | ProductChangeset,
  service: Saveable,
  relation: Relation
) {
  if (!changeset.data.testimonyImages) {
    return;
  }
  const diff = documentsDiff(
    (await model.testimonyImages.toArray()) ?? [],
    changeset.data.testimonyImages
  );

  await saveOrDeleteTestimonyImagesFromDiff(diff, relation, service);
}

async function saveOrDeleteTestimonyImagesFromDiff(
  { created, deleted }: DocumentDiff<FileObject, DocumentModelCommon>,
  relation: Relation,
  service: Saveable
) {
  for (const image of created) {
    const noUndef = fileToFormData(image, relation);
    if (!noUndef) {
      return;
    }
    await service.save(noUndef);
  }

  for (const image of deleted) {
    await image.destroyRecord();
  }
}
