import { boolean, object, string } from 'yup';

const formsUserValidation = object().shape({
  firstName: string().required('validations.first_name.required'),
  lastName: string().required('validations.last_name.required'),
  email: string()
    .email('validations.email.format')
    .required('validations.email.required'),
  role: string(),
  password: string(),
  newsAgreement: boolean(),
});

export default formsUserValidation;
