interface OptionList {
  id: string;
}
/**
 * Utility for filtering selected items from a list of options
 * @param options List of options
 * @param selected List of selected options
 */
export default function (
  options: Partial<OptionList>[],
  selected: Partial<OptionList>[]
): unknown {
  return options.filter((option) => {
    return !selected.some((selectOption) => selectOption.id === option.id);
  });
}
