import type { ProductType } from 'common/enums/product-type.enum';
import type { BaseActionProductDTO, SimilarDTO } from './base-action-product';
import BaseChangeset from 'common/changesets/base';

export type ProductDTO = BaseActionProductDTO & {
  similarProducts: SimilarDTO[];
  type: ProductType;
};

export class ProductChangeset extends BaseChangeset<ProductDTO> {}
