import BaseDocumentService from './base-document';
import type OgImageModel from 'back-office/models/og-image';

export default class OgImageService extends BaseDocumentService<OgImageModel> {
  entityName = 'og-images';
}

declare module '@ember/service' {
  interface Registry {
    ogImage: OgImageService;
  }
}
