var _class, _descriptor, _descriptor2;
function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
import Service from '@ember/service';
import { service } from '@ember/service';
import { assert } from '@ember/debug';
let ErrorHandlerService = (_class = class ErrorHandlerService extends Service {
  constructor(...args) {
    super(...args);
    _initializerDefineProperty(this, "intl", _descriptor, this);
    _initializerDefineProperty(this, "flashMessages", _descriptor2, this);
  }
  handle(changeset, errors) {
    if (Array.isArray(errors)) {
      for (const error of errors) {
        this.generateGlobalErrorMessage(error.code);
      }
      const errorsReturned = this.translateErrors(errors);
      this.addErrorTranslatedInChangeset(changeset, errorsReturned);
    } else {
      this.generateGlobalErrorMessage(errors);
    }
  }
  translateErrors(errors) {
    const translatedErrors = {};
    for (const error of errors) {
      for (const errorDetail of error.details) {
        const {
          field,
          message
        } = errorDetail;
        const messagePath = `validations.backend.${message}`;
        if (!translatedErrors[field]) {
          translatedErrors[field] = [];
        }
        if (this.intl.exists(messagePath)) {
          translatedErrors[field]?.push(this.intl.t(messagePath));
        } else {
          translatedErrors[field]?.push(message);
          assert(`Missing translation for backend error : ${message}`);
        }
      }
    }
    return translatedErrors;
  }
  addErrorTranslatedInChangeset(changeset, errors) {
    for (const field in errors) {
      for (const error of errors[field]) {
        changeset.addError(field, error);
      }
    }
  }
  generateGlobalErrorMessage(error) {
    const messagePath = `validations.backend.${error}`;
    if (this.intl.exists(messagePath)) {
      this.flashMessages.danger(`${this.intl.t('validations.backend.error')} - ${this.intl.t(messagePath)}`);
    } else {
      this.flashMessages.danger(`${this.intl.t('validations.backend.error')} - ${error}`);
    }
  }
}, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
}), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flashMessages", [service], {
  configurable: true,
  enumerable: true,
  writable: true,
  initializer: null
})), _class);
export { ErrorHandlerService as default };