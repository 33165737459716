import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import config from 'back-office/config/environment';

export default class HeadDataService extends Service {
  @tracked declare title: string;
  @tracked description = 'Technofutur TIC';

  get applicationName() {
    return config.applicationName;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
  // eslint-disable-next-line no-unused-vars
  interface Registry {
    'head-data': HeadDataService;
  }
}
