import Service, { service } from '@ember/service';
import type ActionModel from 'back-office/models/action';
import type ProductModel from 'back-office/models/product';
import type CoverService from '../cover';
import type OgImageService from '../og-image';
import type FormatorImageService from '../formator-image';
import type TagModel from 'back-office/models/tag';
import type ArrayProxy from '@ember/array/proxy';
import type { SimilarDTO } from 'back-office/changesets/base-action-product';
import type TestimonyImageService from '../testimony-image';
import type TestimonyImageModel from 'back-office/models/testimony-image';

export default class BaseActionProductService extends Service {
  @service declare cover: CoverService;
  @service declare ogImage: OgImageService;
  @service declare formatorImage: FormatorImageService;
  @service declare testimonyImage: TestimonyImageService;

  public async getCommonProperties(model: ProductModel | ActionModel) {
    const [ogImage, cover, formatorImage] = await Promise.all([
      model.ogImage,
      model.cover,
      model.formatorImage,
      model.testimonyImages,
    ]);

    return {
      id: model.id,
      name: model.name,
      slug: model.slug,
      code: model.code,
      status: model.status,
      ogImage: this.ogImage.getFilePojo(ogImage),
      ogMetadata: {
        title: model.ogMetadata?.title,
        description: model.ogMetadata?.description,
        keywords: model.ogMetadata?.keywords,
      },
      duration: {
        unit: model.duration?.unit,
        value: model.duration?.value,
      },
      cover: this.cover.getFilePojo(cover),
      formatorImage: this.formatorImage.getFilePojo(formatorImage),
      tags: model.tags.map((tag: TagModel) => ({
        id: tag.id,
        name: tag.name,
        type: tag.type,
      })),
      testimonyImages: model.testimonyImages.map(
        (image: TestimonyImageModel) => ({
          id: image.id,
          originalName: image.originalName,
          filename: image.filename,
          path: image.path,
        })
      ),
      testimony: model.testimony,
      video: model.video,
    };
  }

  public getSimilarPojo(
    models: ArrayProxy<ActionModel> | ArrayProxy<ProductModel>
  ): SimilarDTO[] {
    return models.map((model) => {
      return {
        id: model.id,
        name: model.name,
      };
    });
  }
}

declare module '@ember/service' {
  interface Registry {
    'changesets/base-action-product': BaseActionProductService;
  }
}
