import BaseChangeset from 'common/changesets/base';

export type UserDTO = {
  id?: string;
  lastName: string;
  firstName: string;
  email: string;
  role: string;
  newsAgreement: boolean;
};

export class UserChangeset extends BaseChangeset<UserDTO> {}
