/* eslint-disable unicorn/filename-case */
import Service, { service } from '@ember/service';
import { TagChangeset } from 'back-office/changesets/tag';
import type TagModel from 'back-office/models/tag';
import { TagTypes } from 'common/enums/tag-type.enum';
import type StoreService from '@ember-data/store';
import type ExtendedStoreService from '@triptyk/ember-utils/services/extended-store';
import type { ChangesetService } from 'common/services/changesets/changeset-service';

export default class TagService
  extends Service
  implements ChangesetService<TagChangeset>
{
  @service declare store: StoreService;
  @service declare extendedStore: ExtendedStoreService;
  emptyChangeset() {
    return new TagChangeset({
      name: '',
      type: TagTypes.One,
    });
  }

  changesetFrom(id: string) {
    const tag = this.extendedStore.peekOrFail('tag', id);
    const changeset = new TagChangeset({
      id: tag.id,
      name: tag.name,
      type: tag.type,
    });

    return changeset;
  }

  async save(changeset: TagChangeset): Promise<TagModel> {
    changeset.execute();

    const tag = this.extendedStore.peekOrCreateRecord(
      'tag',
      changeset.get('id')
    ) as TagModel;

    tag.setProperties({
      name: changeset.data.name,
      type: changeset.data.type,
    });

    await tag.save();
    await changeset.save();

    return tag;
  }
}

declare module '@ember/service' {
  interface Registry {
    'changesets/tag': TagService;
  }
}
