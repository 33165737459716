import TagModelCommon from 'common/models/tag';

export default class TagModel extends TagModelCommon {}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    tag: TagModel;
  }
}
