import { array, number, object, string } from 'yup';

const FIVE_MEGABYTES = 1024 * 1024 * 5;

export const fileValidationSchema = object().shape({
  filename: string(),
  path: string(),
  id: string(),
  size: number().max(FIVE_MEGABYTES, 'validations.file.max_size'),
});

const formsBaseActionProductValidation = {
  ogImage: fileValidationSchema.optional(),
  ogMetadata: object().shape({
    title: string().optional(),
    description: string().optional(),
    keywords: string().optional(),
  }),
  cover: fileValidationSchema.optional(),
  testimonyImages: array(fileValidationSchema)
    .optional()
    .test('maxItems', 'validations.testimony_image.max_size', (value) => {
      if (value) {
        return value.length <= 3;
      }
      return true;
    }),
  video: string().optional().nullable(),
  testimony: string().optional().nullable(),
  formatorImage: fileValidationSchema.optional(),
};

export default formsBaseActionProductValidation;
