import BaseDocumentService from './base-document';
import type FormatorImageModel from 'back-office/models/formator-image';

export default class FormatorImageService extends BaseDocumentService<FormatorImageModel> {
  entityName = 'formator-images';
}

declare module '@ember/service' {
  interface Registry {
    formatorImage: FormatorImageService;
  }
}
