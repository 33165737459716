import type ArrayProxy from '@ember/array/proxy';
import genericDiff from '@triptyk/ember-utils/utils/generic-diff';
import type { FileObject } from 'back-office/services/base-document';
import type DocumentModelCommon from 'common/models/document';

export interface DocumentDiff<
  T extends FileObject,
  M extends DocumentModelCommon
> {
  deleted: M[];
  created: T[];
}

export default function documentsDiff<
  T extends FileObject,
  M extends DocumentModelCommon
>(
  models: DocumentModelCommon[] | ArrayProxy<M>,
  array: T[]
): DocumentDiff<T, M> {
  return genericDiff(
    models,
    array as unknown as { id?: string }[]
  ) as unknown as DocumentDiff<T, M>;
}
