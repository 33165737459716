const tailwindCommon = require('common/tailwind.config');
const tailwindThemeCommon = tailwindCommon.theme;

module.exports = {
  content: [...tailwindCommon.content],
  corePlugins: {},
  plugins: [],
  theme: {
    extend: {
      ...tailwindThemeCommon.extend,
    },
    colors: {
      ...tailwindThemeCommon.colors,
    },
  },
};
