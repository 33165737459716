import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'back-office/config/environment';
import * as Sentry from '@sentry/ember';

Sentry.init({
  dsn: 'https://2275825587094e9d833ad9ced0e1bb60@o1127730.ingest.sentry.io/4505436502097921',
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 0.5,
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  environment: config.environment,
  enabled:
    config.environment !== 'test' && config.environment !== 'development',
});

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
